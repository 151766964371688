import * as React from 'react';
import * as qs from 'qs';
import _ from 'lodash';
import gql from 'graphql-tag';
import { Query, Mutation, MutationFn } from 'react-apollo';
import { Redirect, RouteComponentProps } from 'react-router-dom';

import * as t from 'inkp-order-sdk/types.g';
import { routes } from 'inkp-routes/public';
import { Subscribe, StateContainer } from 'infra-frontend/helpers/apollo';

import { Design, Mutation as MutationDesignResults, MutationcreateDesignsArgs } from 'inkp-design-sdk/types.g';
import { Product, ProductColor } from 'inkp-product-sdk/types.g';
import { Query as QueryUserResults, User } from 'inkp-user-sdk/types.g';

import { CURRENT_USER_FRAGMENT } from '../../util/login';
import DesignCart, { DesignCartDesign } from '../../states/global/designCart';

import DesignTool from '../DesignTool/index2';
import { CREATE_DESIGNS_MUTATION, UPDATE_CART_MUTATION, CART_QUERY } from '../DesignTool/queries';
import {
  MUTATION_UPDATE_CART,
  MutationFnUpdateCart,
  MutationUpdateCart,
  QUERY_CART_BY_USER,
  QUERY_REQUEST_CART_QUOTE,
  QueryCartByUser,
  createNewCartItems,
  updateCurrentCart,
} from '../../util/Checkout';
import DesignToolStateContainer from '../DesignTool/DesignToolStateContainer';
import { DataProxy } from 'apollo-cache';
import { hasAb } from 'inkp-user-sdk/user';

export const QUERY_CURRENT_USER = gql`
  {
    currentUser {
      ...CurrentUser
      phone
    }
  }

  ${CURRENT_USER_FRAGMENT}
`;

class TypedSubscribe extends Subscribe<[DesignToolStateContainer]> {}

class QueryCurrentUser extends Query<QueryUserResults> {}

interface EditCartDesignState {
  alert: {
    title: string;
    content: string;
  } | null;
}

class PageStateContainer extends StateContainer<EditCartDesignState> {
  initialState = {
    alert: null,
  };

  shape = `
    {
      alert {
        title
        content
      }
    }
  `;

  setAlert = (alertData: { title: string; content: string }) => {
    this.setState({ alert: alertData });
  };

  unsetAlert = () => {
    this.setState({ alert: null });
  };
}

function onNext(props: {
  cart: t.Cart;
  createDesigns: MutationFn<MutationDesignResults, MutationcreateDesignsArgs>;
  user: User;
  designCart: DesignCart;
  designToolStateContainer: DesignToolStateContainer;
  itemIndexes: number[];
  updateCart: MutationFnUpdateCart;
}) {
  const { designToolStateContainer, designCart, user, cart, itemIndexes, createDesigns, updateCart } = props;
  return (newItemData: { product: Product; color: ProductColor }) => {
    // always resave for now, because user could click 'Save Design'
    // if (designCart.state.saved) {
    //   designToolStateContainer.setShowCart(true, false);
    //   return;
    // }
    const hasCartDrawerAB = hasAb(user, 'cart-drawer', 'true');
    if (hasCartDrawerAB) {
      designToolStateContainer.setShowCart(true, true);
    } else {
      designToolStateContainer.setShowSaving(true);
    }
    const designInputs = designCart.state.designs.map(({ sides, userNote }: DesignCartDesign) => {
      return {
        sides,
        userId: user.id,
        shareableId: (cart!.items[itemIndexes[0]].design as Design).shareableId,
        userNote,
      };
    });
    // @ts-ignore: Enum cannot be coereced even if matching with a string
    createDesigns({ variables: { designs: designInputs } }).then((result: any) => {
      const createdDesign = result.data.createDesigns[0];
      const newCartItems = createNewCartItems({
        product: newItemData.product,
        colorName: newItemData.color.name,
        design: createdDesign,
        sizesQuantities: designCart.state.sizesQuantities,
      });
      const currentCartItemInputs: t.CartItemInput[] = cart.items.map((cartItem) => {
        return {
          product: {
            quantity: cartItem.product.quantity,
            productItemId: cartItem.product.productItemId,
            mockupUrl: cartItem.product.mockupUrl,
            mockups: cartItem.product.mockups,
          },
          designId: cartItem.designId,
        };
      });
      /**
       * itemIndexes contains all the indexes that match the cart.items for the current CartProduct that we are editing.
       * These indexes will be consecutive and will be grouped to one CartProduct.
       * Any other cart.items that don't match the itemIndexes, come from the other CartProducts and we will want to keep
       * that information intact. The updated cart items that we get from the `createNewCartItems` function,
       * could be the same quantity, more or less depending on if the user added or removed the quantities from the sizes.
       * We want to ensure the new cart items will be grouped to the correct CartProduct, so the `updatedCartItems` are inserted
       * between the old cart.items.
       */
      const updatedCartItems = [
        ...currentCartItemInputs.slice(0, itemIndexes[0]),
        ...newCartItems,
        ...currentCartItemInputs.slice(itemIndexes[itemIndexes.length - 1] + 1),
      ];
      updateCart({
        variables: {
          id: cart.id,
          data: {
            items: updatedCartItems,
          },
        },
      });
    });
  };
}

function RestoreDesign(props: {
  user: User;
  cart: t.Cart;
  itemIndexes: number[];
  designCart: DesignCart;
  designToolStateContainer: DesignToolStateContainer;
  history: any;
  match: any;
  location: any;
}) {
  const { designCart, designToolStateContainer, cart, itemIndexes, user } = props;
  React.useEffect(() => {
    props.designCart.restoreFromCart(cart, itemIndexes);
  }, [itemIndexes.join(',')]);

  return (
    <Subscribe to={[PageStateContainer]} namespace="EditCartDesign">
      {({ state, setAlert, unsetAlert }) => (
        <MutationUpdateCart
          mutation={MUTATION_UPDATE_CART}
          onCompleted={(data) => {
            const hasCartDrawerAB = hasAb(user, 'cart-drawer', 'true');
            if (hasCartDrawerAB) {
              designToolStateContainer.setShowCart(true, false);
              designToolStateContainer.setShowSaving(false);
            } else {
              return props.history.push({
                pathname: routes.app.checkout.cart,
              });
            }
          }}
          update={(cache: DataProxy, { data: { updateCart } }: any) => {
            updateCurrentCart(cache, updateCart);
          }}
          onError={(e: any) => {
            setAlert({ title: 'Unknown Error', content: 'Please try again later' });
          }}
          refetchQueries={[{ query: QUERY_REQUEST_CART_QUOTE, variables: { cartId: cart.id } }]}
          awaitRefetchQueries={true}
        >
          {(updateCart: MutationFnUpdateCart, { loading: cartLoading }) => (
            <Mutation
              mutation={CREATE_DESIGNS_MUTATION}
              onError={(e: any) => {
                setAlert({ title: 'Unknown Error', content: 'Please try again later' });
              }}
              onCompleted={(data: any) => {
                const design: t.Design | null = data && data.createDesigns && data.createDesigns[0];
                if (design) {
                  designCart.assignEditedDesignIds(design.id);
                }
              }}
            >
              {(createDesigns: MutationFn, { loading: designLoading }: { loading: boolean }) => (
                <DesignTool
                  designCart={designCart}
                  designCartState={designCart.state}
                  onNext={onNext({
                    cart,
                    createDesigns,
                    user,
                    designCart,
                    designToolStateContainer,
                    itemIndexes,
                    updateCart,
                  })}
                  submitting={cartLoading || designLoading}
                  alerts={
                    state.alert
                      ? [{ type: 'error', title: state.alert.title, content: state.alert.content, onClose: unsetAlert }]
                      : undefined
                  }
                  {...props}
                />
              )}
            </Mutation>
          )}
        </MutationUpdateCart>
      )}
    </Subscribe>
  );
}

interface EditCartDesignPageParams {
  itemIndex: string;
}

export default function(props: RouteComponentProps<EditCartDesignPageParams>) {
  const queryParams = qs.parse(`${props.location.search}`, { ignoreQueryPrefix: true });
  return (
    <QueryCurrentUser query={QUERY_CURRENT_USER}>
      {({ error: currentUserError, loading: currentUserLoading, data: currentUserData }) => {
        if (currentUserError) {
          console.error(currentUserError);
          return <div>Error</div>;
        }
        if (currentUserLoading || !currentUserData) {
          return null;
        }
        const { currentUser } = currentUserData;

        const itemIndexes: number[] = queryParams.itemIndexes.split(',').map((n: string) => parseInt(n));

        return (
          <QueryCartByUser query={QUERY_CART_BY_USER}>
            {({ error: cartByUserError, loading: cartByUserLoading, data: cartByUserData }) => {
              if (cartByUserError) {
                return <div>Error</div>;
              }

              if (cartByUserLoading || !cartByUserData) {
                return null;
              }

              if (!cartByUserData.cartByUser.items[itemIndexes[0]]) {
                return <Redirect to={routes.app.base} />;
              }

              return (
                <Subscribe to={[DesignCart]} namespace="Redesign">
                  {(designCart: DesignCart) => (
                    <TypedSubscribe to={[DesignToolStateContainer]} namespace="RedesignDesignToolInteraction">
                      {(designToolStateContainer: DesignToolStateContainer) => {
                        return (
                          <RestoreDesign
                            user={currentUser!}
                            designCart={designCart}
                            designToolStateContainer={designToolStateContainer}
                            cart={cartByUserData.cartByUser}
                            itemIndexes={itemIndexes}
                            {...props}
                          />
                        );
                      }}
                    </TypedSubscribe>
                  )}
                </Subscribe>
              );
            }}
          </QueryCartByUser>
        );
      }}
    </QueryCurrentUser>
  );
}
